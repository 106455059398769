import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { BiPhone } from "react-icons/bi";
import Loader from '../../inc/Loader';
import { agtApiKey, getApiToken, custApiKey, msgToast } from '../../inc/myFunctions';
import API from "../../inc/axios";

function EditProfileNumber() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [editprofile, seteditprofile] = useState({
        phone: ""
    })
    const [errors, setErrors] = useState({});
    const [getData, setData] = useState(null);
    async function getCustProfileData() {
        const newErrors = {};
        const formData = {
            agt_api_key: agtApiKey(),
            cust_api_key: custApiKey()
        }
        try {
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const response = await API.post('/customers/profile.php?api_action=Profile', JSON.stringify(formData), config);
            if (response.status === 200 && response.data.status_code === 1000) {
                setData(true)
                const rs = response.data.cust_detail;
                seteditprofile({
                    ...editprofile,
                    phone: rs.phone
                })
            } else {
                if (typeof response.data.status_msg === "undefined") {

                    console.log(response)
                    newErrors.app_error = "No Data Found";
                } else {
                    newErrors.app_error = response.data.status_msg;
                }
                setErrors(newErrors);
                return;
            }
        } catch (error) {
            console.error(error);
        }

    }
    useEffect(() => {
        getCustProfileData()
    }, []);


    const handleIpunt = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        seteditprofile({ ...editprofile, agt_api_key: agtApiKey(), cust_api_key: custApiKey(), [name]: value })
        setErrors({ ...errors, [name]: '' })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const newErrors = {};
        if (!editprofile.phone) {
            newErrors.phone = "Please Enter Phone Number";
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try {
            setLoading(true);
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const rs = await API.post('/customers/profile.php?api_action=UpPhone', JSON.stringify(editprofile), config);
            const msg = rs.data.status_msg;
            if (rs.status === 200 && rs.data.status_code === 1000) {
                if (msg === "SUCCESSFULL") {
                    msgToast("success", "Mobile Number Updated Successfully", 'top-right')
                } else {
                    msgToast("error", msg, 'top-right')
                }
                setTimeout(function () { navigate("/Profile") }, 1000);
            } else {
                msgToast("error", msg, 'top-right')
                console.log(rs.data)
                return;
            }
        } catch (error) {
            msgToast("error", "An error occurred please try again", 'top-right')
            console.log(error);
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            {loading && <Loader />}
            <div className="container-fuild app-main-card onboard-card">
                <div className="container mx-width">
                    <div className="title-box">
                        <p className="app-title">Update Mobile Number</p>
                    </div>
                </div>
                <div className="container p-0 mx-width">
                    {getData ? (
                        <form method="POST" action="" onSubmit={handleSubmit} className="form-horizontal" autoComplete="off" id="frm_new">

                            <div className="container-fluid p-0">
                                <div className="row m-0">
                                    <div className="col-12  mb-3">
                                        <div className="input-group">
                                            <span className="input-group-text"><BiPhone /></span>
                                            <input type="text" className="form-control" value={editprofile.phone} onChange={handleIpunt} id="phone" name="phone" />
                                        </div>
                                        {errors.phone && <div className="invalid-feedback" style={{ display: "block" }}>{errors.phone}</div>}
                                    </div>
                                    <div className="col-12 mb-3">
                                        <div className="input-group">
                                            <button className="btn btn-primary btn-app">Update</button>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        {errors.app_error && <div className="alert alert-danger">{errors.app_error}</div>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    ) : (
                        errors.app_error ? errors.app_error && (<div></div>) : (
                            <>
                                <div className="col-12 mb-3 placeholder-glow">
                                    <div className="input-group">
                                        <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                    </div>
                                </div>
                                <div className="col-12 mb-3 placeholder-glow">
                                    <div className="input-group">
                                        <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                    </div>
                                </div>
                            </>
                        )
                    )}
                </div>
            </div>
        </>
    );
}

export default EditProfileNumber;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Top from './Top';
import { AiOutlineExclamation } from "react-icons/ai";
import { BiUser, BiUserPin, BiTransfer, BiPlus } from "react-icons/bi";
import { TiDocumentText } from "react-icons/ti";
import { agtApiKey, getApiToken, custApiKey } from '../inc/myFunctions';
import API from "../inc/axios";
function Home() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [getBeneficiary, setBeneficiary] = useState(null);
  async function getbenefData() {
    const newErrors = {};
    const formData = {
      agt_api_key: agtApiKey(),
      cust_api_key: custApiKey()
    }
    try {
      const token = await getApiToken("Yes");
      const config = { headers: { Authorization: `Bearer ` + token } }
      const rs = await API.post('/beneficiary/beneficiary.php?api_action=BeneList', JSON.stringify(formData), config);
      if (rs.status == 200 && rs.data.status_code == 1000) {
        const res = rs.data.bene_detail;
        if (typeof res == "undefined") {
          newErrors.benificiary_error = "Please Add New Beneficiary";
          setErrors(newErrors);
        } else {
          setBeneficiary(res)
        }
      } else {
        const msg = rs.data.status_msg
        console.log(msg)
        newErrors.benificiary_error = rs.data.status_msg;
        setErrors(newErrors);

        if (msg == "INVALID CUSTOMER TOKEN") {
          navigate("/Logout")
        }
        return;
      }
    } catch (error) {
      console.error(error);
      newErrors.benificiary_error = "Please Try Again";
      setErrors(newErrors);
    }

  }
  useEffect(() => {
    getbenefData()
  }, []);
  return (
    <div className="appMain">
      <Top page="home" />
      <div className="container-fuild app-main-card onboard-card">
        <div className="container mx-width">
          <div className="app-alert-box">
            <div className="app-alert-icon"><AiOutlineExclamation /></div><p className="text">Please complete your profile, first</p>
          </div>
        </div>
        {/* Grid container */}
        <div className="container p-0 mx-width">
          <div className="row m-0">
            <div className="col-6 col-md-3">
              <Link className="link" to="/Profile">
                <div className="app-grid">
                  <span className="grid-icon"><BiUser /></span>
                  <p className="text-2">Profile</p>
                  <div className="grid-vector">
                    <svg xmlns="http://www.w3.org/2000/svg" width="155" height="172" viewBox="0 0 155 172" fill="none">
                      <path opacity="0.7" d="M155 172V0C155 0 152 71.5 80 80.5C8 89.5 5 161 5 161L0 172H155Z" fill="rgba(36, 241, 190, 1)" />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-md-3">
              <Link className="link" to="/CustomerDocs">
                <div className="app-grid" style={{ backgroundColor: 'rgba(184, 54, 177, 1)' }}>
                  <span className="grid-icon" style={{ backgroundColor: 'rgba(189, 66, 183, 1)' }}><TiDocumentText /></span>
                  <p className="text-2">ID / Docs</p>
                  <div className="grid-vector">
                    <svg xmlns="http://www.w3.org/2000/svg" width="155" height="172" viewBox="0 0 155 172" fill="none">
                      <path opacity="0.7" d="M155 172V0C155 0 152 71.5 80 80.5C8 89.5 5 161 5 161L0 172H155Z" fill="rgba(188, 74, 182, 1)" />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-md-3">
              <Link className="link" to="/Beneficiary">
                <div className="app-grid" style={{ backgroundColor: 'rgba(1, 153, 229, 1)' }}>
                  <span className="grid-icon" style={{ backgroundColor: 'rgba(36, 165, 230, 1)' }}><BiUserPin /></span>
                  <p className="text-2">Beneficiaries</p>
                  <div className="grid-vector">
                    <svg xmlns="http://www.w3.org/2000/svg" width="155" height="172" viewBox="0 0 155 172" fill="none">
                      <path opacity="0.7" d="M155 172V0C155 0 152 71.5 80 80.5C8 89.5 5 161 5 161L0 172H155Z" fill="rgba(57, 168, 224, 1)" />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-6 col-md-3">
              <Link className="link" to="/Transaction">
                <div className="app-grid" style={{ backgroundColor: 'rgba(237, 92, 123, 1)' }}>
                  <span className="grid-icon" style={{ backgroundColor: 'rgba(238, 101, 131, 1)' }}><BiTransfer /></span>
                  <p className="text-2">Transactions</p>
                  <div className="grid-vector">
                    <svg xmlns="http://www.w3.org/2000/svg" width="155" height="172" viewBox="0 0 155 172" fill="none">
                      <path opacity="0.7" d="M155 172V0C155 0 152 71.5 80 80.5C8 89.5 5 161 5 161L0 172H155Z" fill="rgba(237, 105, 134, 1)" />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Grid container End*/}
        <div className="row">
          <div className="container-fuild fullwidth gray-bg">
            <div className="container mx-width">
              <div className="title-box">
                <p className="app-title-2">Pay To:</p>
                <Link className="link" to="/Beneficiary">View All</Link>
              </div>
            </div>
            <div className="container mx-width">
              <div className="row benif-section">
                <div className="col-4 col-sm-3 col-md-2">
                  <Link to="/Beneficiary/Newbeneficiary">
                    <div className="app-benif-grid">
                      <span className="benif-circle"><BiPlus /></span>
                    </div>
                  </Link>
                </div>
                {errors.benificiary_error && <div className="invalid-feedback" style={{ display: "block" }}>{errors.benificiary_error}</div>}
                {getBeneficiary ? (getBeneficiary.map(rs => (
                  <div className="col-4 col-sm-3 col-md-2" key={rs.bene_id}>
                    <div className="app-benif-grid" onClick={() => navigate('/Beneficiary/ViewBeneficiary', { state: { pid: rs.bene_id } })}>
                      <span className="benif-circle">{(rs.first_name).charAt(0)}{(rs.last_name).charAt(0)}</span>
                      <p className="text-2">{rs.first_name} {rs.last_name}</p>
                    </div>
                  </div>
                ))
                ) : (
                  errors.benificiary_error ? errors.benificiary_error && (<></>) : (
                    <>
                      <div className="col-4 col-sm-3 col-md-2">
                        <div className="app-benif-grid">
                          <span className="placeholder col-7" style={{ height: "47px", borderRadius: "100%" }}></span>
                          <span className="placeholder col-9"></span>
                        </div>
                      </div>
                      <div className="col-4 col-sm-3 col-md-2">
                        <div className="app-benif-grid">
                          <span className="placeholder col-7" style={{ height: "47px", borderRadius: "100%" }}></span>
                          <span className="placeholder col-9"></span>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* closing app-main-card */}
    </div>

  );
}

export default Home;

import React from 'react';
import Logo from '../images/logo.svg';
function Onboartop() {
    return (
        <div className="container-fuild top-bg">
        <div className="container">
          <div className="top-box">
            <img src={Logo} alt="AWS" />
          </div>
        </div>
      </div>
    );
  }
  
  export default Onboartop;
import React, { useState, useEffect } from 'react';
import Loader from '../../inc/Loader';
import { useNavigate } from 'react-router-dom';
import { BiUser, BiPhone, BiHome } from "react-icons/bi";
import { BsBank2, BsHash, BsXOctagonFill } from "react-icons/bs";
import { MdOutlineAccountBalance } from "react-icons/md";
import { agtApiKey, getApiToken, custApiKey, getCountryList, getRelationList, msgToast } from '../../inc/myFunctions';
import API from "../../inc/axios";

function NewBeneficiary(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [newBenef, setnewBenef] = useState({
        ptype: "",
        first_name: "",
        last_name: "",
        phone: "",
        gender: "",
        relationship: "",
        address_1: "",
        country: "",
        bank_name: "",
        ac_title: "",
        ac_no: "",
    })
    const [errors, setErrors] = useState({});
    const handleIpunt = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setnewBenef({ ...newBenef, agt_api_key: agtApiKey(), cust_api_key: custApiKey(), [name]: value })
        setErrors({ ...errors, [name]: '' })
    }
    const handleBenef = (e) => {
        if(e !== ""){
            props.transBenef(e);
        }else{
            props.transBenef('');
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
        if (!newBenef.ptype) {
            newErrors.ptype = "Please Select Payment Type";
        }
        if (!newBenef.first_name) {
            newErrors.first_name = "Please Enter First Name";
        }
        if (!newBenef.last_name) {
            newErrors.last_name = "Please Enter Last Name";
        }
        if (!newBenef.phone) {
            newErrors.phone = "Please Enter Phone / Mobile No.";
        }
        if (!newBenef.gender) {
            newErrors.gender = "Please Select Gender";
        }
        if (!newBenef.relationship) {
            newErrors.relationship = "Please Enter Relationship to Beneficiary";
        }
        if (!newBenef.address_1) {
            newErrors.address_1 = "Please Enter Address";
        }
        if (!newBenef.country) {
            newErrors.country = "Please Select Country";
        }
        if (newBenef.ptype === "BANK") {
            if (!newBenef.bank_name) {
                newErrors.bank_name = "Please Enter Bank Name";
            }
            if (!newBenef.ac_title) {
                newErrors.ac_title = "Please Enter Account Title";
            }
            if (!newBenef.ac_no) {
                newErrors.ac_no = "Please Enter Account Number";
            }
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try {
            setLoading(true);
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const rs = await API.post('/beneficiary/beneficiary.php?api_action=New', JSON.stringify(newBenef), config);
            const msg = rs.data.status_msg;
            if (rs.status === 200 && rs.data.status_code === 1000) {
                if (msg === "SUCCESSFULL") {
                    msgToast("success", "Beneficiary Successfully Added", 'top-right')
                } else {
                    msgToast("error", msg, 'top-right')
                }
                if(props.addFrom ===  "NewTrans"){
                    handleBenef('add');
                }else{
                    navigate("/Beneficiary")
                }
            } else {
                msgToast("error", msg, 'top-right');
                console.log(msg)
                return;
            }
        } catch (error) {
            msgToast("error", "An error occurred please try again", 'top-right')
            console.error(error);
        } finally {
            setLoading(false);
        }
    }
    const [getCountry, setgetCountry] = useState(null);
    const [getRelation, setgetRelation] = useState(null);
    async function getSettings() {
        const country = await getCountryList();
        setgetCountry(country);
        const relation = await getRelationList();
        setgetRelation(relation);
    }
    useEffect(() => {
        getSettings()
    }, []);
    return (
        <>
            {loading && <Loader />}
            <div className="container-fuild app-main-card onboard-card">
                <div className="container mx-width d-flex justify-content-between">
                    <h4 className="app-title">New Beneficiary</h4>
                    { props.addFrom === "NewTrans" && <div className="app-title-2 close"><BsXOctagonFill onClick={()=> handleBenef('')} /></div>}
                </div>
                <div className="container p-0 mx-width">
                    <form method="POST" action="" onSubmit={handleSubmit} className="form-horizontal" autoComplete="off" id="frm_new">

                        <div className="container-fluid p-0">
                            <div className="row m-0">
                                <div className="col-12  mb-3">
                                    <div className="position-relative"><label className="over-lable">Pay Type</label></div>
                                    <select className="form-select" value={newBenef.ptype} onChange={handleIpunt} name="ptype" id="ptype">
                                        <option value="">--Pay Type--</option>
                                        <option value="BANK">BANK</option>
                                        <option value="CARD">CARD</option>
                                    </select>
                                    {errors.ptype && <div className="invalid-feedback" style={{ display: "block" }}>{errors.ptype}</div>}
                                </div>
                                <div className="col-12  mb-3">
                                    <div className="input-group">
                                        <label className="over-lable">First Name</label>
                                        <span className="input-group-text"><BiUser /></span>
                                        <input type="text" className="form-control" value={newBenef.first_name} onChange={handleIpunt} placeholder="First Name" name="first_name" id="first_name" />
                                    </div>
                                    {errors.first_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.first_name}</div>}
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <label className="over-lable">Last Name</label>
                                        <span className="input-group-text"><BiUser /></span>
                                        <input type="text" className="form-control" value={newBenef.last_name} onChange={handleIpunt} placeholder="Last Name" name="last_name" id="last_name" />
                                    </div>
                                    {errors.last_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.last_name}</div>}
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <label className="over-lable">Mobile / Phone</label>
                                        <span className="input-group-text"><BiPhone /></span>
                                        <input type="text" className="form-control" value={newBenef.phone} onChange={handleIpunt} placeholder="Mobile / Phone" id="phone" name="phone" />
                                    </div>
                                    {errors.phone && <div className="invalid-feedback" style={{ display: "block" }}>{errors.phone}</div>}
                                </div>
                                <div className="col-12  mb-3">
                                    <div className="position-relative"><label className="over-lable">Gender</label></div>
                                    <select className="form-select" value={newBenef.gender} onChange={handleIpunt} name="gender" id="gender">
                                        <option value="">--Select Gender--</option>
                                        <option value="MALE">MALE</option>
                                        <option value="FEMALE">FEMALE</option>
                                        <option value="OTHER">OTHER</option>
                                    </select>
                                    {errors.gender && <div className="invalid-feedback" style={{ display: "block" }}>{errors.gender}</div>}
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="position-relative"><label className="over-lable">Relationship</label></div>
                                    <select className='form-select' value={newBenef.relationship} onChange={handleIpunt} id="relationship" name="relationship" >
                                        <option value="">--Select Relationship--</option>
                                        {getRelation && (getRelation.map(rs => (
                                            <>
                                                <option value={rs.title}>{rs.title}</option>
                                            </>))
                                        )}
                                    </select>
                                    {errors.relationship && <div className="invalid-feedback" style={{ display: "block" }}>{errors.relationship}</div>}
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <label className="over-lable">Address</label>
                                        <span className="input-group-text"><BiHome /></span>
                                        <input type="text" className="form-control" value={newBenef.address_1} onChange={handleIpunt} placeholder="Address" id="address_1" name="address_1" />
                                    </div>
                                    {errors.address_1 && <div className="invalid-feedback" style={{ display: "block" }}>{errors.address_1}</div>}
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="position-relative"><label className="over-lable">Country</label></div>
                                    <select className='form-select' value={newBenef.country} onChange={handleIpunt} id="country" name="country" >
                                        <option value="">--Select Country--</option>
                                        {getCountry && (getCountry.map(rs => (
                                            <>
                                                <option value={rs.country_code}>{rs.country_name}</option>
                                            </>))
                                        )}
                                    </select>
                                    {errors.country && <div className="invalid-feedback" style={{ display: "block" }}>{errors.country}</div>}
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <label className="over-lable">Bank Name</label>
                                        <span className="input-group-text"><BsBank2 /></span>
                                        <input type="text" className="form-control" value={newBenef.bank_name} onChange={handleIpunt} placeholder="Bank Name" id="bank_name" name="bank_name" />
                                    </div>
                                    {errors.bank_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.bank_name}</div>}
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <label className="over-lable">Account Title</label>
                                        <span className="input-group-text"><MdOutlineAccountBalance /></span>
                                        <input type="text" className="form-control" value={newBenef.ac_title} onChange={handleIpunt} placeholder="Account Title" id="ac_title" name="ac_title" />
                                    </div>
                                    {errors.ac_title && <div className="invalid-feedback" style={{ display: "block" }}>{errors.ac_title}</div>}
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <label className="over-lable">Account Number</label>
                                        <span className="input-group-text"><BsHash /></span>
                                        <input type="text" className="form-control" value={newBenef.ac_no} onChange={handleIpunt} placeholder="Account No / IBAN #" id="ac_no" name="ac_no" />
                                    </div>
                                    {errors.ac_no && <div className="invalid-feedback" style={{ display: "block" }}>{errors.ac_no}</div>}
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <button className="btn btn-primary btn-app">Submit</button>
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    {errors.app_error && <div className="alert alert-danger">{errors.app_error}</div>}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default NewBeneficiary;

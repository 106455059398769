import React from 'react';
import {Outlet } from 'react-router-dom';
import Top from '../Top';
import 'bootstrap-daterangepicker/daterangepicker.css';

function CustomerDocs() {
    return (
        <div className="appMain">
            <Top />
            <Outlet />
        </div>

    );
}

export default CustomerDocs;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChevronBack } from "react-icons/io5";
import { isApp } from '../inc/myFunctions';
function Backbtn(props) {
  let navigate = useNavigate();
  if (window.history.state && window.history.state.idx > 0) {
    if (props.page !== "home") {
      return (
        <>
          <div className="back" onClick={() => navigate(-1)}><IoChevronBack /></div>
        </>
      );
    } else {
      return <div className="back"></div>;
    }

  } else {
    if(isApp()){
      navigate('/Logout', { replace: true });
    }
    return <div className="back"></div>;
  }

}

export default Backbtn;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiSearch } from "react-icons/bi";
import { agtApiKey, getApiToken, custApiKey, dateFm, strToArray } from '../../inc/myFunctions';
import API from "../../inc/axios";

function TransactionList() {
    const navigate = useNavigate();
    const [getData, setData] = useState(null);
    const [errors, setErrors] = useState({});
    const [search, setSearch] = useState({});
    async function gettransData() {
        const newErrors = {};
        const getBenef = {
            agt_api_key: agtApiKey(),
            cust_api_key: custApiKey()
        }
        try {
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const rs = await API.post('/transactions/trans.php?api_action=getTrans', JSON.stringify(getBenef), config);
            if (rs.status === 200 && rs.data.status_code === 1000) {
                const res = rs.data.trans;
                if (typeof res === "undefined") {
                    newErrors.app_error = "No Transaction Found";
                    setErrors(newErrors);
                    console.log(rs.data)
                } else {
                    setData(res)
                }
            } else {
                console.log(rs.data)
                newErrors.app_error = rs.data.status_msg;
                setErrors(newErrors);
                return;
            }
        } catch (error) {
            console.error(error);
        }

    }

    useEffect(() => {
        gettransData()
    }, []);
    const handleIpunt = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setSearch({ ...search, [name]: value })
    }
    return (
        <>
            <div className="container-fuild app-main-card onboard-card">
                <div className="container mx-width">
                    <div className="title-box">
                        <p className="app-title-2">Transaction List</p>
                        <Link className="link" to="/Transaction/NewTransaction">New Transaction</Link>
                    </div>
                </div>
                <div className="container p-0 mx-width">
                    <div className="row m-0">
                        {errors.app_error && <div className="invalid-feedback" style={{ display: "block" }}>{errors.app_error}</div>}
                        {
                            getData && (
                                <div className="col-12 mb-3">
                                    <div className="input-group search">
                                        <span className="input-group-text"><BiSearch /></span>
                                        <input type="text" className="form-control" onChange={handleIpunt} placeholder="Search" name="search" id="search" />
                                    </div>
                                </div>
                            )
                        }
                        {getData ? (getData.map(rs => (
                            <div className="col-12 col-md-6" key={rs.pin_code}>
                                <div className="card app-card trans-card" onClick={() => navigate('/Transaction/ViewTransaction', { state: { pid: rs.pin_code } })}>
                                    <div className="card-body">
                                        <h5 className="card-title">{rs.pin_code}
                                            {rs.trans_status === "Compelete" ?
                                                (<span className="badge bg-success">{rs.trans_status}</span>) : (<span className="badge bg-warning text-dark">{rs.trans_status}</span>)
                                            }
                                        </h5>
                                        <p className="card-text mb-0 ">{rs.bene_name}
                                            {rs.payment_status === "Paid" ?
                                                (<span className="badge bg-success ms-2 me-1">{rs.payment_status}</span>) : (<span className="badge bg-primary ms-2 me-1">{rs.payment_status}</span>)
                                            }
                                            <span className="badge bg-primary me-1">{rs.payout_type}</span>
                                        </p>

                                        <p className="card-text mb-0">

                                            <span className="badge bg-light text-dark me-1">{rs.pay_in_amt} {rs.pay_in_cur}</span>
                                            to
                                            <span className="badge bg-light text-dark ms-1">{rs.pay_out_amt} {rs.pay_out_cur}</span>
                                        </p>
                                        <p className="card-text mb-0">{rs.payout_comp_name} {dateFm(rs.trans_date)}</p>
                                        {rs.compliance_alert !== "" ?
                                            (<p className="card-text mb-0">{strToArray(rs.compliance_alert).map((rs, index) => (<span key={index} className="badge bg-danger me-1">{rs}</span>))}</p>) : null
                                        }


                                    </div>
                                </div>
                            </div>
                        ))
                        ) : (
                            errors.app_error ? errors.app_error && (<></>) : (
                                <>
                                    <div className="col-12 placeholder-glow" >
                                        <span className="placeholder col-12 mb-4" style={{ height: "30px" }}></span>
                                    </div>
                                    <div className="col-12 col-md-6" >
                                        <div className="card app-card">
                                            <div className="card-body placeholder-glow">
                                                <span className="placeholder btn-app-card-ph">Edit</span>
                                                <span className="placeholder col-7"></span>
                                                <span className="placeholder col-9"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6" >
                                        <div className="card app-card">
                                            <div className="card-body placeholder-glow">
                                                <span className="placeholder btn-app-card-ph">Edit</span>
                                                <span className="placeholder col-9"></span>
                                                <span className="placeholder col-5"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6" >
                                        <div className="card app-card">
                                            <div className="card-body placeholder-glow">
                                                <span className="placeholder btn-app-card-ph">Edit</span>
                                                <span className="placeholder col-8"></span>
                                                <span className="placeholder col-7"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6" >
                                        <div className="card app-card">
                                            <div className="card-body placeholder-glow">
                                                <span className="placeholder btn-app-card-ph">Edit</span>
                                                <span className="placeholder col-6"></span>
                                                <span className="placeholder col-9"></span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        )}
                    </div>


                </div>
            </div>
        </>
    );
}

export default TransactionList;

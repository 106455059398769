import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../inc/Loader';
import Onboartop from './Onboardtop';
import { BiUser, BiLockAlt } from "react-icons/bi";
import { agtApiKey, getApiToken, msgToast } from '../inc/myFunctions';
import API from "../inc/axios";
function Signin() {
  useEffect(() => {
    localStorage.clear();
  });
  const navigate = useNavigate();
  const [userLogin, setuserLogin] = useState({
    cust_login_id: "",
    cust_login_pass: ""
  })
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const handleIpunt = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setuserLogin({ ...userLogin, agt_api_key: agtApiKey(), [name]: value })
    setErrors({ ...errors, [name]: '' })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    const newErrors = {};
    if (!userLogin.cust_login_id) {
      newErrors.cust_login_id = "Please Enter User Name";
    }
    if (!userLogin.cust_login_pass) {
      newErrors.cust_login_pass = "Please Enter Login Password";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      setLoading(true);
      const token = await getApiToken("Yes");
      const config = { headers: { Authorization: `Bearer ` + token } }
      const rs = await API.post('/customers/login.php?api_action=Login', JSON.stringify(userLogin), config);
      if (rs.status === 200 && rs.data.status_code === 1000) {
        localStorage.setItem("cust_token", btoa(rs.data.cust_api_key))
        const msg = rs.data.status_msg;
        if (msg === "SUCCESSFULL") {
          msgToast("success", "Successfully Login", 'top-right')
        } else {
          msgToast("error", msg, 'top-right')
        }
        navigate("/")

      } else {
        const msg = rs.data.status_msg;
        msgToast("error", msg, 'top-right')
        return;
      }
    } catch (error) {
      msgToast("error", "An error occurred please try again", 'top-right')
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className="appMain">
      {loading && <Loader />}
      <Onboartop />
      <div className="container-fuild app-main-card onboard-card">
        <div className="container mx-width">
          <h4 className="app-title">Sign In</h4>
        </div>
        <div className="container p-0 mx-width">
          <form method="POST" action="" onSubmit={handleSubmit} autoComplete="off" className="form-horizontal" id="frm_new">
            <div className="container-fluid p-0">
              <div className="row m-0">
                <div className="col-12 mb-3">
                  <div className="input-group">
                    <span className="input-group-text"><BiUser /></span>
                    <input type="text" className="form-control" value={userLogin.cust_login_id} onChange={handleIpunt} placeholder="Login ID" id="cust_login_id" name="cust_login_id" />
                  </div>
                  {errors.cust_login_id && <div className="invalid-feedback" style={{ display: "block" }}>{errors.cust_login_id}</div>}
                </div>
                <div className="col-12 mb-3">
                  <div className="input-group">
                    <span className="input-group-text"><BiLockAlt /></span>
                    <input type="password" className="form-control" value={userLogin.cust_login_pass} onChange={handleIpunt} placeholder="Password" id="cust_login_pass" name="cust_login_pass" />
                  </div>
                  {errors.cust_login_pass && <div className="invalid-feedback" style={{ display: "block" }}>{errors.cust_login_pass}</div>}
                </div>
                <p className="text-2 text-end"><Link className="link" to="/Forgetpass">Forget Password?</Link></p>
                <div className="col-12 mb-3">
                  <div className="input-group">
                    <button className="btn btn-primary btn-app">Sign In</button>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  {errors.app_error && <div className="alert alert-danger">{errors.app_error}</div>}
                </div>
              </div>
            </div>
          </form>
          <p className="text-center">Don’t have an account please <Link className="link" to="/Signup">Sign up</Link></p>
        </div>
      </div>
      {/* closing app-main-card */}
    </div>

  );
}

export default Signin;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../inc/Loader';
import NewBeneficiary from '../Beneficiary/NewBeneficiary';
import { agtApiKey, getApiToken, custApiKey, msgToast, getBaseCur, getPayCountries, getPayMode, getCompany, getRate, transPurpose } from '../../inc/myFunctions';
import API from "../../inc/axios";
import { BiMoney, BiPound, BiChevronLeft, BiChevronRight, BiPlus, BiUser } from "react-icons/bi";
import { BsBank2, BsHash, BsBank, BsCashStack, BsWallet } from "react-icons/bs";
import { MdOutlineAccountBalance } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
function NewTransaction() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [transSectoin, setTransSectoin] = useState(true);
    const [isBenef, setIsBenef] = useState(false);
    const [editBenef, setEditBenef] = useState(false);
    const [viewBenef, setViewBenef] = useState(false);
    const [newTrans, setnewTrans] = useState({
        bene_id: "",
        pay_in_curr: "",
        pay_out_country: "",
        payout_mode: "",
        pay_out_comp_id: "",
        pay_out_curr: "",
        base_rate: "",
        pay_out_amt: "",
        pay_in_amt: "",
        trans_purpose: ""
    })
    const [errors, setErrors] = useState({});
    const handleIpunt = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setnewTrans({ ...newTrans, agt_api_key: agtApiKey(), cust_api_key: custApiKey(), [name]: value })
        setErrors({ ...errors, [name]: '' })
        if (name === "pay_out_comp_id") {
            let index = e.target.selectedIndex;
            let optionElement = e.target.childNodes[index]
            let option = optionElement.getAttribute('data-curr');
            newTrans.pay_out_curr = option;
            setnewTrans(newTrans => ({ ...newTrans, pay_out_curr: option }));
            setErrors({ ...errors, [name]: '' })
        }
    }
    const handleSubmit = async (e) => {
        if (e !== "") {
            e.preventDefault()
        }
        const newErrors = {};
        if (!newTrans.bene_id) {
            newErrors.bene_id = "Please Select Beneficiary";
        }
        if (!newTrans.pay_in_curr) {
            newErrors.pay_in_curr = "Please Select Send Money From";
        }
        if (!newTrans.pay_out_curr) {
            newErrors.pay_out_curr = "Pay out currency is not defiend";
        }
        if (!newTrans.pay_out_country) {
            newErrors.pay_out_country = "Please Select Transfer Money Country";
        }
        if (!newTrans.payout_mode) {
            newErrors.payout_mode = "Please Select Payout Mode";
        }
        if (!newTrans.pay_out_comp_id) {
            newErrors.pay_out_comp_id = "Please Select Payout Company";
        }
        if (!newTrans.trans_purpose) {
            newErrors.trans_purpose = "Please Select Transaction Purpose";
        }
        if (!newTrans.base_rate || newTrans.base_rate <= 0) {
            newErrors.base_rate = "Base rate not defiend";
        }
        if (!newTrans.pay_out_amt || newTrans.pay_out_amt <= 0) {
            newErrors.pay_out_amt = "please enter pay out amount";
        }
        if (!newTrans.pay_in_amt || newTrans.pay_in_amt <= 0) {
            newErrors.pay_in_amt = "Please enter pay in amount";
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try {
            setLoading(true);
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const rs = await API.post('/transactions/trans.php?api_action=newTrans', JSON.stringify(newTrans), config);
            const msg = rs.data.status_msg;
            if (rs.status === 200 && rs.data.status_code === 1000) {
                if (msg === "SUCCESSFULL") {
                    msgToast("success", "Transaction Created Successfully", 'top-right')
                } else {
                    msgToast("error", msg, 'top-right')
                }
                navigate("/Transaction");
            } else {
                msgToast("error", msg, 'top-right')
                console.log(rs.data)
                return;
            }
        } catch (error) {
            msgToast("error", "An error occurred please try again", 'top-right')
            console.log(error);
        } finally {
            setLoading(false);
        }
    }
    const [currentStep, setCurrentStep] = useState(1);
    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };
    const previousStep = () => {
        setCurrentStep(currentStep - 1);
    };
    const handleBenef = (e) => {
        setTransSectoin(false)
        if (e === "new") {
            setIsBenef(true);
            setEditBenef(false);
            setViewBenef(false);
            return false
        }
        if (e === "edit") {
            setIsBenef(false);
            setEditBenef(true);
            setViewBenef(false);
            return false
        }
        if (e === "view") {
            setIsBenef(false);
            setEditBenef(false);
            setViewBenef(true);
            return false
        }
        if (e !== "") {
            getbenefData();
        }
        setIsBenef(false);
        setEditBenef(false);
        setViewBenef(false);
        setTransSectoin(true)

    };
    const [getbenfData, setbenfData] = useState(null);
    //const [getBaseCurList, setgetBaseCurList] = useState(null);
    const [payCntry, setpayCntry] = useState(null);
    const [getPayModeList, setgetPayModeList] = useState(null);
    const [getCompanyList, setgetCompanyList] = useState(null);
    const [getBaseRate, setgetBaseRate] = useState(null);
    const [getTransPurpose, setTransPurpose] = useState(null);
    const [showBtn, setshowBtn] = useState(false);
    const [active, setActive] = useState();
    const handleFunctions = async (e, str) => {
        if (str === "payCntry") {
            setnewTrans(newTrans => ({ ...newTrans, pay_out_country: "", payout_mode: "", pay_out_comp_id: "", base_rate: "" }));
            setpayCntry(null)
            setgetPayModeList(null);
            setgetCompanyList(null);
            setgetBaseRate(null);
            setshowBtn(false);
        }
        if (str === "payCntry" && e.target.value !== "") {
            setLoading(true)
            const payCountry = await getPayCountries(e.target.value);
            setpayCntry(payCountry);
            setLoading(false)
        }
        if (str === "payMode") {
            setnewTrans(newTrans => ({ ...newTrans, payout_mode: "", pay_out_comp_id: "", base_rate: "" }));
            setgetPayModeList(null);
            setgetCompanyList(null);
            setgetBaseRate(null);
        }
        if (str === "payMode" && e.target.value !== "") {
            setLoading(true)
            const payMode = await getPayMode(newTrans.pay_in_curr, e.target.value);
            setgetPayModeList(payMode);
            setLoading(false)
        }
        if (str === "getCompany") {
            setnewTrans(newTrans => ({ ...newTrans, pay_out_comp_id: "", base_rate: "" }));
            setgetCompanyList(null);
            setgetBaseRate(null);
        }
        if (str === "getCompany" && e !== "") {
            setLoading(true)
            setnewTrans(newTrans => ({ ...newTrans, payout_mode: e }));
            const gCompany = await getCompany(newTrans.pay_out_country, e);
            setgetCompanyList(gCompany);
            const transp = await transPurpose();
            setTransPurpose(transp);
            setLoading(false)
        }
        if (str === "getCompany") {
            setnewTrans(newTrans => ({ ...newTrans, base_rate: "" }));
            setgetBaseRate(null);
        }
        if (str === "getRate" && e.target.value !== "" && newTrans.pay_out_curr !== "") {
            setLoading(true)
            const rate = await getRate(newTrans.pay_in_curr, newTrans.pay_out_country, newTrans.pay_out_curr, newTrans.payout_mode, e.target.value);
            setgetBaseRate(Number(rate.exch_rate) + 1);
            var base_rate = rate.exch_rate;
            newTrans.base_rate = base_rate;
            setnewTrans(newTrans => ({ ...newTrans, base_rate: base_rate }));

            newTrans.exchange_rate = rate.exch_charges;
            setnewTrans(newTrans => ({ ...newTrans, exchange_rate: rate.exch_charges }));
            let pay_in_amt = newTrans.pay_in_amt;
            if (pay_in_amt === "") {
                pay_in_amt = 100;
            }
            let pay_out_amt = (pay_in_amt * newTrans.base_rate).toFixed(2);
            setnewTrans(newTrans => ({ ...newTrans, pay_in_amt: pay_in_amt, pay_out_amt: pay_out_amt }));
            setshowBtn(false);
            setLoading(false)
        }
        if (str === "GBPCal" && newTrans.base_rate !== "" && newTrans.base_rate > 0) {
            if (e.target.value === "" || e.target.value <= 0) {
                setnewTrans(newTrans => ({ ...newTrans, pay_out_amt: "" }));
                setshowBtn(false);
                return false;
            }
            let pay_out_amt = (e.target.value * newTrans.base_rate).toFixed(2);
            setnewTrans(newTrans => ({ ...newTrans, pay_out_amt: pay_out_amt }));
        }
        if (str === "otherCal" && newTrans.base_rate !== "" && newTrans.base_rate > 0) {
            if (e.target.value === "" || e.target.value <= 0) {
                setnewTrans(newTrans => ({ ...newTrans, pay_in_amt: "" }));
                return false;
            }
            let pay_in_amt = (e.target.value / newTrans.base_rate).toFixed(2);
            setnewTrans(newTrans => ({ ...newTrans, pay_in_amt: pay_in_amt }));
        }
    }
    async function checkFirstStep() {
        if (newTrans.pay_in_curr === "" || newTrans.pay_out_country === "" || newTrans.payout_mode === "" || newTrans.pay_out_curr === "" || newTrans.pay_out_amt <= 0 || newTrans.pay_in_amt <= 0) {
            handleSubmit("")
            return false;
        } else {
            nextStep();
            await getbenefData();
            return true
        }
    };
    function currentActive(id) {
        setActive(id);
    }
    async function checkSecondStep() {
        const newErrors = {};
        if (newTrans.pay_in_curr === "" || newTrans.pay_out_country === "" || newTrans.payout_mode === "" || newTrans.pay_out_curr === "" || newTrans.pay_out_amt <= 0 || newTrans.pay_in_amt <= 0 || newTrans.bene_id <= 0) {
            handleSubmit("");
            newErrors.app_error = "Invalid Input";
            setErrors(newErrors);
            return false;
        } else {
            setLoading(true);
            nextStep();
            const transp = await transPurpose();
            setTransPurpose(transp);
            setLoading(false);
            return true
        }
    };
    const autoTrans = async (cur_code) => {
        newTrans.pay_in_curr = cur_code;
        setnewTrans(newTrans => ({ ...newTrans, pay_in_curr: cur_code }));
        let payCountry = await getPayCountries(cur_code);
        setpayCntry(payCountry);
        newTrans.pay_out_country = payCountry[0].country_name;
        setnewTrans(newTrans => ({ ...newTrans, pay_out_country: payCountry[0].country_name }));
        const payMode = await getPayMode(newTrans.pay_in_curr, newTrans.pay_out_country);
        setgetPayModeList(payMode);
        newTrans.payout_mode = payMode[0].payout_mode;
        setnewTrans(newTrans => ({ ...newTrans, payout_mode: payMode[0].payout_mode }));
        // const gCompany = await getCompany(newTrans.pay_out_country, newTrans.payout_mode);
        // setgetCompanyList(gCompany);
        // newTrans.pay_out_curr = gCompany[0].payout_curr;
        // newTrans.pay_out_comp_id = gCompany[0].payout_comp_id;
        // setnewTrans(newTrans => ({ ...newTrans, pay_out_comp_id: gCompany[0].payout_comp_id, pay_out_curr: gCompany[0].payout_curr }));
        // const rate = await getRate(newTrans.pay_in_curr, newTrans.pay_out_country, newTrans.pay_out_curr, newTrans.payout_mode, newTrans.pay_out_comp_id);
        // setgetBaseRate(Number(rate) + 1);
        // var base_rate = rate;
        // newTrans.base_rate = base_rate;
        // setnewTrans(newTrans => ({ ...newTrans, base_rate: base_rate }));
        // let pay_in_amt = newTrans.pay_in_amt;
        // if (pay_in_amt === "") {
        //     pay_in_amt = 100;
        // }
        // let pay_out_amt = (pay_in_amt * base_rate).toFixed(2);
        // newTrans.pay_in_amt = pay_in_amt;
        // newTrans.pay_out_amt = pay_out_amt;
        // setnewTrans(newTrans => ({ ...newTrans, pay_in_amt: pay_in_amt, pay_out_amt: pay_out_amt }));
    }
    async function selectBenef(id) {
        newTrans.bene_id = id;
        setnewTrans(newTrans => ({ ...newTrans, bene_id: id }));
        setLoading(true)
        let formData = {
            agt_api_key: agtApiKey(),
            cust_api_key: custApiKey(),
            bene_id: id
        }
        try {
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const response = await API.post('/beneficiary/beneficiary.php?api_action=Profile', JSON.stringify(formData), config);
            if (response.status === 200 && response.data.status_code === 1000) {
                const rs = response.data.bene_detail;
                setnewTrans(newTrans => ({
                    ...newTrans,
                    bank_name: rs.bank_name,
                    ac_title: rs.ac_title,
                    ac_no: rs.ac_no,
                    ben_name: rs.first_name + " " + rs.last_name
                }));
            } else {
                if (typeof response.data.status_msg === "undefined") {
                    msgToast("success", "Beneficiary Not Found", 'top-right')
                    console.log(response)
                } else {
                    msgToast("success", response.data.status_msg, 'top-right');
                }
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false)
    }
    async function getbenefData() {
        const newErrors = {};
        const json = {
            agt_api_key: agtApiKey(),
            cust_api_key: custApiKey()
        }
        try {
            setLoading(true)
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const rs = await API.post('/beneficiary/beneficiary.php?api_action=BeneList', JSON.stringify(json), config);
            if (rs.status === 200 && rs.data.status_code === 1000) {
                const res = rs.data.bene_detail;
                if (typeof res === "undefined") {
                    setbenfData([{ "bene_id": "", "first_name": "No", "last_name": "benificiary" }])
                } else {
                    setbenfData(res)
                }
                setLoading(false)
            } else {
                setLoading(false)
                console.log(rs.data.status_msg)
                newErrors.app_error = rs.data.status_msg;
                setErrors(newErrors);
                return;
            }
        } catch (error) {
            setLoading(false)
            console.error(error);
        }
    }
    useEffect(() => {
        async function getSettings() {
            setLoading(true)
            const baseCurrency = await getBaseCur();
            //setgetBaseCurList(baseCurrency);
            let cur_code = "";
            baseCurrency.forEach(rs => {
                cur_code = rs.cur_code;
                if (typeof rs.is_default !== "undefined") {
                    if (rs.is_default === "Yes") {
                        cur_code = rs.cur_code;
                        return true;
                    }
                }
            });
            if (cur_code !== "") {
                await autoTrans(cur_code);
            }
            setLoading(false)
        }
        getSettings()
    }, []);
    return (
        <>
            {loading && <Loader />}
            {isBenef && <NewBeneficiary transBenef={handleBenef} addFrom="NewTrans" />}
            {editBenef && <NewBeneficiary transBenef={handleBenef} addFrom="NewTrans" />}
            {viewBenef && <NewBeneficiary transBenef={handleBenef} addFrom="NewTrans" />}
            {transSectoin &&
                <div className="container-fuild app-main-card onboard-card">
                    <div className="container mx-width">
                        <h4 className="app-title">New Transaction</h4>
                    </div>
                    <div className="container p-0 mx-width">
                        <form method="POST" action="" onSubmit={handleSubmit} className="form-horizontal" autoComplete="off" id="frm_new">
                            <div className="container-fluid p-0">
                                <div className="row m-0">
                                    {currentStep === 1 &&
                                        <>
                                            {payCntry &&
                                                <div className="col-12 mb-3">
                                                    <div className="position-relative"><label className="over-lable">Send Money To</label></div>
                                                    <select className='form-select' value={newTrans.pay_out_country || ""} onChange={(e) => {
                                                        handleIpunt(e); handleFunctions(e, "payMode");
                                                    }} id="pay_out_country" name="pay_out_country" >
                                                        {payCntry && (payCntry.map((rs, index) => (
                                                            <option key={index} value={rs.country_name}>{rs.country_name}</option>))
                                                        )}
                                                    </select>
                                                    {errors.pay_out_country && <div className="invalid-feedback" style={{ display: "block" }}>{errors.pay_out_country}</div>}
                                                </div>
                                            }
                                            {getPayModeList &&
                                                <>
                                                    <div className="col-12 mb-1">
                                                        <h4 className="app-title-2 sub-title">Payout Option</h4>
                                                    </div>
                                                    {getPayModeList && (getPayModeList.map((rs, index) => {
                                                        if (rs.payout_mode === "Bank") {
                                                            return <div className="col-4 col-sm-3 col-md-2" key={index}>
                                                                <div className={rs.payout_mode === active ? 'app-benif-grid shadow-sm  active' : 'app-benif-grid shadow-sm'} onClick={() => { handleFunctions(rs.payout_mode, "getCompany"); currentActive(rs.payout_mode) }}>
                                                                    <span className="benif-circle"><BsBank /></span>
                                                                    <p className="text-2">{rs.payout_mode}</p>
                                                                </div>
                                                            </div>
                                                        }
                                                        if (rs.payout_mode === "Cash") {
                                                            return <div className="col-4 col-sm-3 col-md-2" key={index}>
                                                                <div className={rs.payout_mode === active ? 'app-benif-grid shadow-sm  active' : 'app-benif-grid shadow-sm'} onClick={() => { handleFunctions(rs.payout_mode, "getCompany"); currentActive(rs.payout_mode) }}>
                                                                    <span className="benif-circle"><BsCashStack /></span>
                                                                    <p className="text-2">{rs.payout_mode}</p>
                                                                </div>
                                                            </div>
                                                        }
                                                        if (rs.payout_mode === "Wallet") {
                                                            return <div className="col-4 col-sm-3 col-md-2" key={index}>
                                                                <div className={rs.payout_mode === active ? 'app-benif-grid shadow-sm  active' : 'app-benif-grid shadow-sm'} onClick={() => { handleFunctions(rs.payout_mode, "getCompany"); currentActive(rs.payout_mode) }}>
                                                                    <span className="benif-circle"><BsWallet /></span>
                                                                    <p className="text-2">{rs.payout_mode}</p>
                                                                </div>
                                                            </div>
                                                        }
                                                    })
                                                    )}
                                                    {errors.payout_mode && <div className="invalid-feedback" style={{ display: "block" }}>{errors.payout_mode}</div>}
                                                </>
                                            }
                                            {getCompanyList &&
                                                <div className="col-12 mb-3">
                                                    <div className="position-relative"><label className="over-lable">Payout Company</label></div>
                                                    <select className='form-select' value={newTrans.pay_out_comp_id || ""} onChange={(e) => {
                                                        handleIpunt(e); handleFunctions(e, "getRate");
                                                    }} id="pay_out_comp_id" name="pay_out_comp_id" >
                                                        <option value="" data-curr="">--Select Payout Company--</option>
                                                        {getCompanyList && (getCompanyList.map((rs, index) => (
                                                            <option key={index} data-curr={rs.payout_curr} value={rs.payout_comp_id}>{rs.payout_comp_name}</option>
                                                        ))
                                                        )}
                                                    </select>
                                                    {errors.pay_out_comp_id && <div className="invalid-feedback" style={{ display: "block" }}>{errors.pay_out_comp_id}</div>}
                                                </div>
                                            }
                                            {getBaseRate &&
                                                <>
                                                    <div className="col-12  mb-3">
                                                        <div className="input-group">
                                                            <label className="over-lable">Pay In Amount</label>
                                                            <span className="input-group-text"><BiPound /></span>
                                                            <input type="number" className="form-control" value={newTrans.pay_in_amt || ""} onInput={(e) => {
                                                                handleIpunt(e); handleFunctions(e, "GBPCal");
                                                            }} placeholder="0.00" name="pay_in_amt" id="pay_in_amt" />
                                                        </div>
                                                        {errors.pay_in_amt && <div className="invalid-feedback" style={{ display: "block" }}>{errors.pay_in_amt}</div>}
                                                    </div>
                                                    <div className="col-6  mb-3">
                                                        <div className="input-group">
                                                            <label className="over-lable">Exchange Rate</label>
                                                            <span className="input-group-text"><BiMoney /></span>
                                                            <input type="text" className="form-control" value={getBaseRate - 1} disabled />
                                                        </div>
                                                        {errors.base_rate && <div className="invalid-feedback" style={{ display: "block" }}>{errors.base_rate}</div>}
                                                    </div>
                                                    <div className="col-6  mb-3">
                                                        <div className="input-group">
                                                            <label className="over-lable">Charges</label>
                                                            <span className="input-group-text"><BiPound /></span>
                                                            <input type="text" className="form-control" value={newTrans.exchange_rate} disabled />
                                                        </div>
                                                        {errors.base_rate && <div className="invalid-feedback" style={{ display: "block" }}>{errors.base_rate}</div>}
                                                    </div>
                                                    <div className="col-12  mb-3">
                                                        <div className="input-group">
                                                            <label className="over-lable">Pay Out Amount</label>
                                                            <span className="input-group-text"><GiMoneyStack /></span>
                                                            <input type="number" className="form-control" value={newTrans.pay_out_amt || ""} onInput={(e) => {
                                                                handleIpunt(e); handleFunctions(e, "otherCal");
                                                            }} placeholder="0.00" name="pay_out_amt" id="pay_out_amt" />
                                                        </div>
                                                        {errors.pay_out_amt && <div className="invalid-feedback" style={{ display: "block" }}>{errors.pay_out_amt}</div>}
                                                    </div>
                                                    <div className="col-12 mb-3">
                                                        <div className="input-group">
                                                            <button onClick={checkFirstStep} className="btn btn-primary btn-app">Next <BiChevronRight className='fs-4' /></button>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                    {currentStep === 2 &&
                                        <>
                                            <div className="col-12 mb-2">
                                                <div className='money-info-header'>
                                                    <div>
                                                        <h5>Sending Amount</h5>
                                                        <p>{Number(newTrans.pay_in_amt) + Number(newTrans.exchange_rate)} <span>{newTrans.pay_in_curr}</span></p>
                                                    </div>
                                                    <div>
                                                        <h5>Recipient Amount</h5>
                                                        <p>{newTrans.pay_out_amt} <span>{newTrans.pay_out_curr}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-1">
                                                <h4 className="app-title-2 sub-title">Pay To:</h4>
                                            </div>

                                            {errors.bene_id && <div className="invalid-feedback" style={{ display: "block" }}>{errors.bene_id}</div>}
                                            <div className='col-12'>
                                                <div className='row benif-section'>
                                                    <div className="col-4 col-sm-3 col-md-2">
                                                        <div className="app-benif-grid shadow-sm" onClick={() => setIsBenef(true)}>
                                                            <span className="benif-circle"><BiPlus /></span>
                                                        </div>
                                                    </div>
                                                    {getbenfData && (getbenfData.map((rs) => (
                                                        <div className="col-4 col-sm-3 col-md-2" key={rs.bene_id}>
                                                            <div className={rs.bene_id === active ? 'app-benif-grid shadow-sm  active' : 'app-benif-grid shadow-sm'} onClick={() => { selectBenef(rs.bene_id); currentActive(rs.bene_id) }}>
                                                                <span className="benif-circle">{(rs.first_name).charAt(0)}{(rs.last_name).charAt(0)}</span>
                                                                <p className="text-2">{rs.first_name} {rs.last_name}</p>
                                                            </div>
                                                        </div>))
                                                    )}
                                                </div>
                                            </div>
                                            <div className='col-12'>

                                            </div>
                                            <div className="col-12  mb-3">
                                                <div className="input-group">
                                                    <label className="over-lable">Name</label>
                                                    <span className="input-group-text"><BiUser /></span>
                                                    <input type="text" className="form-control" value={newTrans.ben_name} placeholder="Name" name="ben_name" id="ben_name" />
                                                </div>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <div className="input-group">
                                                    <label className="over-lable">Bank Name</label>
                                                    <span className="input-group-text"><BsBank2 /></span>
                                                    <input type="text" className="form-control" value={newTrans.bank_name} onChange={handleIpunt} placeholder="Bank Name" id="bank_name" name="bank_name" />
                                                </div>
                                                {errors.bank_name && <div className="invalid-feedback" style={{ display: "block" }}>{errors.bank_name}</div>}
                                            </div>
                                            <div className="col-12 mb-3">
                                                <div className="input-group">
                                                    <label className="over-lable">Account Title</label>
                                                    <span className="input-group-text"><MdOutlineAccountBalance /></span>
                                                    <input type="text" className="form-control" value={newTrans.ac_title} onChange={handleIpunt} placeholder="Account Title" id="ac_title" name="ac_title" />
                                                </div>
                                                {errors.ac_title && <div className="invalid-feedback" style={{ display: "block" }}>{errors.ac_title}</div>}
                                            </div>
                                            <div className="col-12 mb-3">
                                                <div className="input-group">
                                                    <label className="over-lable">Account Number</label>
                                                    <span className="input-group-text"><BsHash /></span>
                                                    <input type="text" className="form-control" value={newTrans.ac_no} onChange={handleIpunt} placeholder="Account No / IBAN #" id="ac_no" name="ac_no" />
                                                </div>
                                                {errors.ac_no && <div className="invalid-feedback" style={{ display: "block" }}>{errors.ac_no}</div>}
                                            </div>
                                            <div className="col-6 mb-3">
                                                <div className="input-group">
                                                    <button onClick={previousStep} className="btn btn-primary btn-app"><BiChevronLeft className='fs-4' /> Back</button>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-3">
                                                <div className="input-group">
                                                    <button onClick={checkSecondStep} className="btn btn-primary btn-app">Next <BiChevronRight className='fs-4' /></button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {currentStep === 3 &&
                                        <>
                                            {getTransPurpose &&
                                                <div className="col-12 mb-3">
                                                    <div className="position-relative"><label className="over-lable">Transaction Purpose</label></div>
                                                    <select className='form-select' value={newTrans.trans_purpose || ""} onChange={(e) => {
                                                        handleIpunt(e);
                                                    }} id="trans_purpose" name="trans_purpose" >
                                                        <option value="">--Select Transaction Purpose--</option>
                                                        {getTransPurpose && (getTransPurpose.map((rs, index) => (
                                                            <option key={index} value={rs.title}>{rs.title}</option>
                                                        ))
                                                        )}
                                                    </select>
                                                    {errors.trans_purpose && <div className="invalid-feedback" style={{ display: "block" }}>{errors.trans_purpose}</div>}
                                                </div>
                                            }
                                            <div className="col-6 mb-3">
                                                <div className="input-group">
                                                    <button onClick={previousStep} className="btn btn-primary btn-app"><BiChevronLeft className='fs-4' /> Back</button>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-3">
                                                <div className="input-group">
                                                    <button className="btn btn-primary btn-app">Submit</button>
                                                </div>
                                            </div>
                                            {/* {showBtn &&
                                                
                                            } */}
                                        </>
                                    }


                                    <div className="col-12 mb-3">
                                        {errors.app_error && <div className="alert alert-danger">{errors.app_error}</div>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </>
    );
}
export default NewTransaction;

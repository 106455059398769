import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../images/logo.svg';
import { BiUserPin, BiTransfer, BiHomeAlt } from "react-icons/bi";
import { TiDocumentText } from "react-icons/ti";
import Avatar from '../images/avatar.jpg';
import { chkLogin } from '../inc/myFunctions';
import Backbtn from '../inc/Backbtn';
import { HiOutlineBars3CenterLeft } from "react-icons/hi2";
import { FiLogOut } from "react-icons/fi";
function Top(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (chkLogin()) {
      navigate("/signin");
    }
  },[]);
  let page = "";
  if(props.page === "home"){
    page = "home";
  }
  return (
    <>
      <div className="container-fuild top-bg home-top">
        <div className="container">
          <div className="top-box">
            <div className="menu-left">
              <Backbtn page={page} />
            </div>

            <Link to="/"><img className="logo" src={Logo} alt="AWS" /></Link>
            <div className="menu-right">
              <Link to="/Profile" className="profile"><img src={Avatar} alt="/Profile" /></Link>
              <div className="nav-trigger" data-bs-toggle="offcanvas" data-bs-target="#app-side-nav"><HiOutlineBars3CenterLeft /></div>
            </div>

          </div>
        </div>
      </div>
      <div className="offcanvas offcanvas-start" id="app-side-nav">
        <div className="offcanvas-header">
          <h1 className="offcanvas-title">Menu</h1>
          <div className="btn-close" data-bs-dismiss="offcanvas"></div>
        </div>
        <div className="offcanvas-body">
          <Link className="link" to="/">
            <div className="app-nav-link">
              <BiHomeAlt /><span>Home</span>
            </div>
          </Link>
          <Link className="link" to="/Beneficiary">
            <div className="app-nav-link">
              <BiUserPin /><span>Beneficiary</span>
            </div>
          </Link>
          <Link className="link" to="/CustomerDocs">
            <div className="app-nav-link">
              <TiDocumentText /><span>ID / Docs</span>
            </div>
          </Link>
          <Link className="link" to="/Transaction">
            <div className="app-nav-link">
              <BiTransfer /><span>Transactions</span>
            </div>
          </Link>
          <Link className="link" to="/Logout">
            <div className="app-nav-link">
              <FiLogOut /><span>Logout</span>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Top;
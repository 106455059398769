import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { agtApiKey, getApiToken, custApiKey,dateFm } from '../../inc/myFunctions';
import API from "../../inc/axios";

function ViewCustomerDocs() {
    const navigate = useNavigate();
    const location = useLocation();
    const [errors, setErrors] = useState({});
    const [getData, setData] = useState(null);
    const pid = location.state;
    async function getCustDocsData(pid) {
        const newErrors = {};
        const formData = {
            agt_api_key: agtApiKey(),
            cust_api_key: custApiKey(),
            doc_id: pid
        }
        try {
            if (pid === "" || pid === null || typeof pid === "undefined") {
                newErrors.app_error = "Invalid Request";
                setErrors(newErrors);
                navigate("/CustomerDocs")
                return;
            }
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const response = await API.post('/customers/docs.php?api_action=DocDetail', JSON.stringify(formData), config);
            if (response.status === 200 && response.data.status_code === 1000) {
                const rs = response.data.doc_detail
                setData(rs)
            } else {
                if (typeof response.data.status_msg === "undefined") {
                    console.log(response)
                    newErrors.app_error = "No Data Found";
                } else {
                    newErrors.app_error = response.data.status_msg;
                }
                setErrors(newErrors);
                return;
            }
        } catch (error) {
            console.error(error);
        }

    }
    useEffect(() => {
        if (typeof pid !== "undefined" && pid !== null) {
            getCustDocsData(location.state.pid)
        } else {
            navigate("/CustomerDocs")
        }

    }, []);

    return (
        <div className="container-fuild app-main-card onboard-card">
            <div className="container mx-width">
                <h4 className="app-title">View ID</h4>
            </div>
            <div className="container p-0 mx-width">
                {errors.app_error && <div className="invalid-feedback" style={{ display: "block" }}>{errors.app_error}</div>}
                {getData ? (
                    <div className="container-fluid p-0">
                        <div className="row m-0">
                            <div className="col-12  mb-3">
                                <ul className="list-group list-group-flush app-list">
                                    <li className="list-group-item"><p>ID Type</p><p>{getData.id_type}</p></li>
                                    <li className="list-group-item"><p>ID Number</p><p>{getData.id_no}</p></li>
                                    <li className="list-group-item"><p>ID Issue by</p><p>{getData.id_issue_by}</p></li>
                                    <li className="list-group-item"><p>ID Country</p><p>{getData.id_country}</p></li>
                                    <li className="list-group-item"><p>Issue Date</p><p>{dateFm(getData.id_issue_date)}</p></li>
                                    <li className="list-group-item"><p>Expiry Date</p><p>{dateFm(getData.id_expiry_date)}</p></li>
                                    <li className="list-group-item"><p className="w-100 mb-2">ID Details</p><p className="w-100">{getData.id_detail}</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                ) : (
                    errors.app_error ? errors.app_error && (<div></div>) : (
                        <>
                            <div className="col-12 mb-3 placeholder-glow">
                                <div className="input-group">
                                    <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                </div>
                            </div>
                            <div className="col-12 mb-3 placeholder-glow">
                                <div className="input-group">
                                    <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                </div>
                            </div>
                            <div className="col-12 mb-3 placeholder-glow">
                                <div className="input-group">
                                    <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                </div>
                            </div>
                            <div className="col-12 mb-3 placeholder-glow">
                                <div className="input-group">
                                    <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                </div>
                            </div>
                            <div className="col-12 mb-3 placeholder-glow">
                                <div className="input-group">
                                    <span className="placeholder col-12 mb-4" style={{ height: "59px" }}></span>
                                </div>
                            </div>
                        </>
                    )
                )}
            </div>
        </div>

    );
}

export default ViewCustomerDocs;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiSearch,BiEdit,BiShow } from "react-icons/bi";
import { agtApiKey, getApiToken, custApiKey } from '../../inc/myFunctions';
import API from "../../inc/axios";

function BeneficiaryList() {
    const navigate = useNavigate();
    const [getData, setData] = useState(null);
    const [errors, setErrors] = useState({});
    const [search, setSearch] = useState({});
    async function getbenefData() {
        const newErrors = {};
        const getBenef = {
            agt_api_key: agtApiKey(),
            cust_api_key: custApiKey()
        }
        try {
            const token = await getApiToken("Yes");
            const config = { headers: { Authorization: `Bearer ` + token } }
            const rs = await API.post('/beneficiary/beneficiary.php?api_action=BeneList', JSON.stringify(getBenef), config);
            if (rs.status === 200 && rs.data.status_code === 1000) {
               const res = rs.data.bene_detail;
                if(typeof res === "undefined"){
                    newErrors.app_error = "Please Add New Beneficiary";
                    setErrors(newErrors);
                }else{
                    setData(res)
                }
            } else {
                console.log(rs.data.status_msg)
                newErrors.app_error = rs.data.status_msg;
                setErrors(newErrors);
                return;
            }
        } catch (error) {
            console.error(error);
        }

    }
    useEffect(() => {
        getbenefData()
    }, []);
    const handleIpunt = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setSearch({ ...search, [name]: value })
    }
    return (
        <>
            <div className="container-fuild app-main-card onboard-card">
                <div className="container mx-width">
                    <div className="title-box">
                        <p className="app-title-2">Beneficiaries</p>
                        <Link className="link" to="/Beneficiary/NewBeneficiary">Add New</Link>
                    </div>
                </div>
                <div className="container p-0 mx-width">
                    <div className="row m-0">
                        {errors.app_error && <div className="invalid-feedback" style={{ display: "block" }}>{errors.app_error}</div>}
                        {
                            getData && (
                                <div className="col-12 mb-3">
                                    <div className="input-group search">
                                        <span className="input-group-text"><BiSearch /></span>
                                        <input type="text" className="form-control" onChange={handleIpunt} placeholder="Search" name="search" id="search" />
                                    </div>
                                </div>
                            )
                        }
                        {getData ? (getData.map(rs => (
                            <div className="col-12 col-md-6" key={rs.bene_id}>
                                <div className="card app-card">
                                    <div className="card-body">
                                        <button type="button" onClick={() => navigate('/Beneficiary/ViewBeneficiary', { state: { pid: rs.bene_id } })} className="btn btn-app-card view btn-sm"><BiShow/></button>
                                        <button type="button" onClick={() => navigate('/Beneficiary/EditBeneficiary', { state: { pid: rs.bene_id } })} className="btn btn-app-card edit btn-sm"><BiEdit/></button>
                                        <h5 className="card-title">{rs.first_name} {rs.last_name}</h5>
                                        <p className="card-text">{rs.bank_name}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                        ) : (
                            errors.app_error ? errors.app_error && (<></>) : (
                                <>
                                    <div className="col-12 placeholder-glow" >
                                        <span className="placeholder col-12 mb-4" style={{ height: "30px" }}></span>
                                    </div>
                                    <div className="col-12 col-md-6" >
                                        <div className="card app-card">
                                            <div className="card-body placeholder-glow">
                                                <span className="placeholder btn-app-card-ph">Edit</span>
                                                <span className="placeholder col-7"></span>
                                                <span className="placeholder col-9"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6" >
                                        <div className="card app-card">
                                            <div className="card-body placeholder-glow">
                                                <span className="placeholder btn-app-card-ph">Edit</span>
                                                <span className="placeholder col-9"></span>
                                                <span className="placeholder col-5"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6" >
                                        <div className="card app-card">
                                            <div className="card-body placeholder-glow">
                                                <span className="placeholder btn-app-card-ph">Edit</span>
                                                <span className="placeholder col-8"></span>
                                                <span className="placeholder col-7"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6" >
                                        <div className="card app-card">
                                            <div className="card-body placeholder-glow">
                                                <span className="placeholder btn-app-card-ph">Edit</span>
                                                <span className="placeholder col-6"></span>
                                                <span className="placeholder col-9"></span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        )}
                    </div>


                </div>
            </div>
        </>
    );
}

export default BeneficiaryList;
